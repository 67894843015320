/* Alerts */
.alert-notification-wrapper {
    position: fixed;
    left: $sidebar-width;
    right: 0;
    z-index: 999;

    @include media-breakpoint-down(md) {
        left: 0;
    }

    &.top {
        top: $header-height;
    }
}