/* Jquery Toast */

.jq-toast-wrap {
    &.top-right {
        top: calc(#{$header-height} + 20px);
    }

    &.top-center {
        top: calc(#{$header-height} + 20px);
        left: 0;
        right: 0;
    }

    &.top-left {
        top: calc(#{$header-height} + 20px);
        left: calc(#{$sidebar-width} + #{$body-margin-x});
    }
}