/* Header */

.t-header {
  display: flex;
  height: $header-height;
  background: $header-bg;
  z-index: $header-zindex;

  @include media-breakpoint-down(md) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .t-header-brand-wrapper {
    display: flex;
    align-items: center;
    height: $header-height;
    width: $sidebar-width;
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    background: $brand-header-bg;
    z-index: $header-zindex;
    padding-left: 18px;

    a {
      display: flex;
      align-items: center;
      color: theme-color(light);
      @include font-face($TYPE-1, 500);
      @include font-size(20);

      .logo {
        max-width: 100%;
        width: $logo-width;
      }

      .logo-mini {
        display: none;
        max-width: 100%;
        width: $logo-mini-width;
      }

      p {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
      justify-content: center;

      a {
        .logo-mini {
          width: 150px;
        }
      }
    }
  }

  .t-header-content-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    padding: 0 $body-margin-x;

    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }

    .t-header-search-box {
      display: flex;
      width: 40%;
      height: 45px;
      border: none;
      background: darken($template-body-color, 4%);
      border-radius: 4px;
      transition: 0.3s ease-in-out;
      transition-property: "background";

      @media (max-width: 580px) {
        display: none;
      }

      .input-group-prepend {
        .input-group-text {
          border: none;
          background: transparent;
          @include font-size(24);
          padding-left: 15px;
          line-height: 23px;

        }
      }


      .form-control {
        height: inherit;
        border: none;
        background: transparent;
        @include font-size(14);
        @include font-face($TYPE-1, 500);
        padding-left: 0;

        @include input-placeholder {
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
        }
      }

      &:hover {
        background: darken($template-body-color, 7%);
      }
    }

    .t-header-content {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;

      .nav {
        .nav-item {
          .nav-link {
            position: relative;
            font-family: $TYPE-2;

            i {
              color: $sidebar-link-color;
            }

            .notification-indicator {
              position: absolute;
              top: 12px;
              right: 12px;
            }
          }

          &:last-child {
            .nav-link {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  .t-header-toggler {
    background: transparent;
    border: none;
    margin-left: auto;

    svg.logo {
      width: 50px;
      height: 50px;
      cursor: pointer;
      transform: translate3d(0, 0, 0);

      path {
        fill: none;
        transition: stroke-dashoffset 0.35s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.35s cubic-bezier(0.25, -0.25, 0.75, 1.25);
        stroke-width: 8px;
        stroke-linecap: round;
        stroke: $body-text-inverse-color;
        stroke-dashoffset: -20px;
      }

      .top,
      .bottom,
      .middle {
        stroke-dasharray: 60px 300px;
      }
    }

    &.arrow {
      path {

        &.top,
        &.bottom {
          stroke-dasharray: 25px 300px;
          stroke-dashoffset: -230px;
        }
      }
    }

    i {
      @include font-size(22);
    }

    &.t-header-mobile-toggler {
      margin-left: 0;
      margin-right: 15px;
    }
  }

  &.fixed-top {
    position: fixed;
  }
}

.header-fixed {
  .t-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: $header-zindex;
  }

  .sidebar {
    padding-top: $header-height;

    .t-header-brand-wrapper {
      position: fixed;
      left: 0;
      top: 0;
      z-index: $header-zindex;
      width: $sidebar-width;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
    }

    .navigation-menu {
      z-index: 1;
    }
  }
}